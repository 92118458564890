import React from "react";
import { AddHeader } from "../components/Home/AddHeader";
import { SocialMedia } from "../components/Home/SocialMedia";
import { Feature } from "../components/Home/Feature";
import { Choosekansensus } from "../components/Home/Choosekansensus";
import { Closer } from "../components/Home/Closer";
import { JoinWaitlist } from "../components/Home/JoinWaitList";
import { Ourusers } from "../components/Home/Ourusers";
const HomePage = () => {
  return (
    <div className="home-page">
      <div className="add-header">
        <AddHeader />
      </div>
      <div className="social-media-home-page mt-5">
        <SocialMedia />
      </div>
      <div className="features-home-page mt-5">
        <Feature />
      </div>
      <div className="choose-kansensus-home-page mt-5">
        <Choosekansensus />
      </div>
      <div className="our-users-home-page mt-5">
        {/* <Ourusers /> */}
      </div>
      <div className="close-home-page mt-5">
        <Closer />
      </div>
      <div className="joinwaitlist-home-page mt-5">
        <JoinWaitlist />
      </div>
    </div>
  );
};
export { HomePage };
