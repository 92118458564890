import React from "react";

const AboutUs = () => {
  return (
    <div className="terms-and-condition container">
      <h1>About Us</h1>
      <div className="mt-3 mb-5">
        {/* <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <br />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <br />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <br />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <br /> */}
        <p>
          Welcome to Giorgham, where innovation meets connection. We are a
          dynamic tech company dedicated to transforming the way people interact
          with their friends and family. Our mission is to bridge distances,
          simplify everyday tasks, and create a more vibrant and expressive
          digital experience for everyone.
        </p>
        <br />
        <p>
          At Giorgham, we believe that staying connected should be easy, fun,
          and meaningful. That’s why we’ve developed a social media platform
          that not only keeps you close to your loved ones but also helps you
          manage the practicalities of life. With features like our intuitive
          invoicing system and customizable avatar reactions, Giorgham is
          designed to make your social interactions more engaging and your
          shared experiences more seamless.
        </p>
        <br />
        <p>
          Our team is passionate about blending technology with human
          connection. We understand that in today’s fast-paced world, it's
          important to have tools that bring convenience without sacrificing the
          warmth of personal interactions. Giorgham is more than just an app;
          it's a community where connections thrive and responsibilities are
          shared effortlessly.
        </p>
        <br />
        <p>
          Join us on our journey to redefine social connectivity. At Giorgham,
          we’re committed to continually innovating and enhancing the ways you
          stay in touch with those who matter most.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
