import React from "react";
import { Col, Row } from "react-bootstrap";

import FeatureImage from "../../assets/images/Home/Features/leftaddimage.png";

const Feature = () => {
  const features = [
    {
      title: "Social Connectivity",
      description:
        "Easily share updates, photos, and videos with your network.",
      id: 1,
    },
    {
      title: "Invoicing System",
      description:
        "Seamlessly manage and split expenses with friends and family.",
      id: 2,
    },
    {
      title: "Custom Avatars",
      description: "React to posts with fun and expressive avatars.",
      id: 3,
    },
    {
      title: "Private Messaging",
      description:
        "Stay in touch with one-on-one chats or create group conversations.",
      id: 4,
    },
    {
      title: "Event Planning",
      description:
        "Organize and invite your circle to events, with expense tracking built right in.",
      id: 5,
    },
  ];
  return (
    <>
      <div className="features-component container" id="feature">
        <Row>
          <Col className="feature-image mt-5" xs={12} md={12} lg={6}>
            <img src={FeatureImage} alt="" />
          </Col>
          <Col className="feature-details mt-5" xs={12} md={12} lg={6}>
            <div className="content mt-1">
              {/* <h5>Features</h5> */}
              <h1>
                Features <span>Giorgham</span>
              </h1>
              <div className="feature-list mt-5">
                {features.map((feature, index) => (
                  <div key={feature.id} className="feature-item mb-3">
                    <input
                      type="radio"
                      id={`feature${index}`}
                      disabled
                      checked={index % 2 === 0}
                    />
                    <label htmlFor={`feature${index}`}>
                      <h3>{feature.title}</h3>
                      <p className="mb-2">{feature.description}</p>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export { Feature };
