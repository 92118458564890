import React from "react";

import Addposter from "../../assets/images/Home/Choosesec/addposter.png";
import { Col, Row } from "react-bootstrap";
const Choosekansensus = () => {
  return (
    <>
      <div className="choose-kansensus-component container">
        <Row>
          <Col className="choose-kansensus-details mt-5" xs={12} md={12} lg={6}>
            <div className="content mt-1">
              {/* <h5>Why Choose kansensus</h5> */}
              <h1>
                Effortless Expense Sharing
                {/* <span>Influence Change</span> */}
              </h1>
              <div className="para mt-3">
                <p>
                  Say goodbye to awkward money conversations. Giorgham's
                  built-in invoicing system, makes splitting bills and tracking
                  shared expenses easier than ever. Whether it's a group dinner,
                  a vacation, or just a casual outing, our platform ensures that
                  everyone pays their share without any hassle
                </p>
                <br />
                <p>
                  Why settle for just likes and comments? With Giorgham, you can
                  react to posts using custom avatars that reflect your
                  personality and mood. Show your excitement, support, or
                  surprise in a way that words alone can’t capture.
                </p>
              </div>
            </div>
          </Col>
          <Col
            className="choose-kansensus-image mt-5"
            xs={12}
            md={12}
            lg={{ span: 4, offset: 2 }}
          >
            <img src={Addposter} alt="" />
          </Col>
        </Row>
      </div>
    </>
  );
};
export { Choosekansensus };
