import React from "react";
const CookiePolicy = () => {
  return (
    <>
      <div className="privacy-policy terms-and-condition container">
        <h1>Cookie Policy</h1>
        <div className="mt-3 mb-5">
          {/* <h2 className="mb-3">What is Lorem Ipsum?</h2> */}
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p> */}
          <p>
            A cookie policy is a statement that explains how your website or
            application uses cookies, what types of cookies are used, and how
            users can manage or opt-out of cookie usage. Here's a sample cookie
            policy:
          </p>
          <br />
          <h5>Cookie Policy</h5>
          <p>
            Giorgham uses cookies to improve user experience, track
            website usage, and provide personalized content. By using our
            website, you consent to our cookie policy.
          </p>
          <br />
          <h5>What are cookies?</h5>
          <p>
            Cookies are small text files stored on your device that help us
            understand how you interact with our website.
          </p>
          <br />
          <h5>Types of cookies we use</h5>
          <p>
            <strong>Essential cookies</strong>: Necessary for website
            functionality.
          </p>
          <p>
            <strong>Analytical cookies</strong>: Track website usage and
            performance.
          </p>
          <p>
            <strong>Marketing cookies</strong>: Provide personalized content and
            ads.
          </p>
          <br />

          <h5>Managing cookies</h5>
          <p>
            <strong>Accept cookies</strong>: Continue browsing to accept
            cookies.
          </p>
          <p>
            <strong>Decline cookies</strong>: Disable cookies in your browser
            settings.
          </p>
          <p>
            <strong>Opt-out</strong>: Contact us to request removal of personal
            data.
          </p>
          <br />
          <h5>Changes to this policy</h5>
          <p>
            We may update our cookie policy. Please check this page for changes.
          </p>
          <br />
        </div>
      </div>
    </>
  );
};
export { CookiePolicy };
