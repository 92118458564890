import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-condition container">
      <h1>Terms And Conditions</h1>
      <div className="mt-3 mb-5">
        <p>
          Welcome to Giorgham! By using our services, you agree to comply with
          the following terms and conditions ("Terms"). Please read them
          carefully.
        </p>
        <br />
        <h5>1. Acceptance of Terms</h5>
        <p>
          By accessing or using the Giorgham app, you agree to be bound by these
          Terms. If you do not agree to these Terms, please do not use our app.
        </p>
        <br />
        <h5>2. User Accounts</h5>
        <p>
          Registration: To access certain features, you must create an account.
          You agree to provide accurate and complete information during
          registration. • Account Security: You are responsible for maintaining
          the confidentiality of your account information, including your
          password. You agree to notify us immediately of any unauthorized use
          of your account. • Termination: We reserve the right to suspend or
          terminate your account at any time for any reason, including violation
          of these Terms.
        </p>
        <br />
        <h5>3. User Conduct</h5>
        <p>
          Prohibited Activities: You agree not to engage in any illegal,
          harmful, or disruptive behavior while using Giorgham. This includes,
          but is not limited to, harassment, spamming, and distributing
          malicious software. • Content Ownership: You retain ownership of the
          content you post on Giorgham. By posting content, you grant us a
          non-exclusive, royalty-free license to use, modify, and display your
          content on our platform.
        </p>
        <br />
        <h5>4. Invoicing and Payments</h5>
        <p>
          Invoicing System: Giorgham provides an invoicing feature for splitting
          expenses among users. You agree to use this feature responsibly and
          accurately. • Payment Disputes: Giorgham is not responsible for any
          disputes that arise from the use of the invoicing system. Users are
          encouraged to resolve disputes directly.
        </p>
        <br />
        <h5>5. Avatar Reactions</h5>
        <p>
          Giorgham allows users to react to posts using custom avatars. You
          agree not to create or use avatars that are offensive, discriminatory,
          or violate the rights of others.
        </p>
        <br />
        <h5>6. Privacy</h5>
        <p>
          Your privacy is important to us. Please review our Privacy Policy to
          understand how we collect, use, and share your information.
        </p>
        <br />
        <h5>7. Modifications to the Service</h5>
        <p>
          We reserve the right to modify, suspend, or discontinue any part of
          the Giorgham app at any time without notice. We are not liable for any
          modifications, suspensions, or discontinuations of the service.
        </p>
        <br />
        <h5>8. Disclaimer of Warranties</h5>
        <p>
          Giorgham is provided "as is" without warranties of any kind. We do not
          guarantee that the app will be error-free, secure, or uninterrupted.
        </p>
        <br />
        <h5>9. Limitation of Liability</h5>
        <p>
          To the fullest extent permitted by law, Giorgham shall not be liable
          for any damages arising out of your use or inability to use the app.
        </p>
        <br />
        <h5>10. Governing Law</h5>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of United States, Any disputes arising under these Terms will be
          subject to the exclusive jurisdiction of the courts of United States.
        </p>
        <br />
        <h5>11. Contact Us</h5>
        <p>
          If you have any questions about these Terms, please contact us at
          gcgaffiliate@gmail.com.
        </p>
      </div>
    </div>
  );
};
export { TermsAndConditions };
