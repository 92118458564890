import React from "react";
const Privacy = () => {
  return (
    <>
      <div className="privacy-policy terms-and-condition container">
        <h1>Privacy Policy</h1>
        <div className="mt-3 mb-5">
          <p>
            Giorgham respects your privacy and is committed to protecting your
            personal information. This Privacy Policy explains how we collect,
            use, and share your information when you use our app.
          </p>
          <br />
          <h5>1. Information We Collect</h5>
          <p>
            Personal Information: When you create an account, we may collect
            personal information such as your name, email address, phone number,
            and profile picture. Usage Data: We collect information about how
            you use Giorgham, including the content you post, the people you
            interact with, and the features you use. Device Information: We
            collect information about the device you use to access Giorgham,
            including your device’s IP address, operating system, and browser
            type.
          </p>
          <br />

          <h5>2. How We Use Your Information</h5>
          <p>
            To Provide and Improve Our Services: We use your information to
            operate and enhance the Giorgham app, including personalizing your
            experience and providing customer support. To Communicate with You:
            We may use your contact information to send you updates,
            notifications, and promotional offers. To Enforce Our Terms: We use
            your information to ensure compliance with our Terms of Service and
            to prevent fraud or misuse of our app.
          </p>
          <br />

          <h5>3. Sharing Your Information</h5>
          <p>
            With Other Users: Your profile information and content you share may
            be visible to other users on Giorgham. With Third-Party Service
            Providers: We may share your information with service providers who
            help us operate the app, such as hosting services, payment
            processors, and analytics providers. For Legal Reasons: We may
            disclose your information if required by law, or if we believe it is
            necessary to protect our rights, property, or safety, or that of
            others.
          </p>
          <br />

          <h5>4. Cookies and Tracking Technologies</h5>
          <p>
            Giorgham uses cookies and similar tracking technologies to collect
            and store information about your interactions with our app. You can
            control the use of cookies through your browser settings.
          </p>
          <br />

          <h5>5. Data Security</h5>
          <p>
            We implement appropriate security measures to protect your
            information from unauthorized access, alteration, disclosure, or
            destruction. However, no internet or mobile application can be
            completely secure, and we cannot guarantee the absolute security of
            your data.
          </p>
          <br />

          <h5>6. Data Retention</h5>
          <p>
            We retain your personal information for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy or as required
            by law.
          </p>
          <br />
          <h5>7. Your Rights</h5>
          <p>
             Access and Update: You have the right to access and update your
            personal information at any time through your account settings. •
            Delete Account: You can delete your Giorgham account at any time,
            and we will remove your information from our active databases.
            However, some information may be retained for legal or
            administrative purposes.
          </p>
          <br />
          <h5>8. Children’s Privacy</h5>
          <p>
            Giorgham is not intended for use by children under the age of 13. We
            do not knowingly collect personal information from children under
            13. If we become aware that we have collected such information, we
            will take steps to delete it.
          </p>
          <br />
          <h5>9. Changes to This Privacy Policy</h5>
          <p>
            We may update this Privacy Policy from time to time. When we make
            changes, we will revise the "Effective Date" at the top of this
            policy. We encourage you to review this policy periodically to stay
            informed about how we are protecting your information.
          </p>
          <br />
          <h5>10. Contact Us</h5>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at
          </p>
          <br />
        </div>
      </div>
    </>
  );
};
export { Privacy };
