import React from "react";
import { Col, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";

import card from "../../assets/images/Home/CARD.svg";
import first from "../../assets/images/Home/TopHeader/1.png";
import second from "../../assets/images/Home/TopHeader/2.png";
import third from "../../assets/images/Home/TopHeader/3.png";
import mobile from "../../assets/images/Home/verticalmobile.png";

const AddHeader = () => {
  return (
    <>
      <div className="add-header-component container">
        <Row>
          <Col className="head-data mt-5" xs={12} md={12} lg={6}>
            <p className="speak-up">Stay Connected, Stay Close</p>
            <p className="bottom-speak ">
              Giorgham is the ultimate social media platform designed to bring
              friends and family closer, no matter where they are in the world.
              With Giorgham, you can share moments, celebrate milestones, and
              stay in touch with the people who matter most.
            </p>
            <div className="d-flex bottom-details mt-5">
              <div className="img-three-add">
                <img src={first} className="first" alt="" />
                <img src={third} className="second" alt="" />
                <img src={second} className="third" alt="" />
              </div>
              <div className="happy-customer ">
                <div className="count">20,291</div>
                <div className="para">Happy Customers</div>
              </div>
              <div className="vertical-line"></div>
              <div className="rating-details ">
                <div className="rating-avg">4.8/5</div>
                <div className="rating-diag d-flex">
                  <Rating size={25} readonly initialValue={4} /> Rating
                </div>
              </div>
            </div>
          </Col>
          <Col
            className="ban-img d-none d-lg-block mt-5"
            md={{ span: 5, offset: 1 }}
          >
            <div className="vertical-img image-fluid">
              <img
                src={mobile}
                className="image-fluid vertical-image-css"
                alt=""
              />
              <div className="upper-image">
                {/* <img src={card} className="upper-image-css" alt=""/> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export { AddHeader };
