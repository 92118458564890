import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/Home";
import { GeneralLayout } from "../assets/Layouts/GeneralLayout";
import { Testing } from "../pages/NotFound";
import { Privacy } from "../pages/Privacy";
import { TermsAndConditions } from "../pages/Terms";
import { CookiePolicy } from "../pages/CookiePolicy";
import { ContactUs } from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";

const Navigation = () => {
  return (
    <>
      <Routes>
        <Route
          path={"/"}
          element={<GeneralLayout children={<HomePage />} />}
        ></Route>
        <Route
          path={"/privacy-policy"}
          element={<GeneralLayout className={"mb-5"} children={<Privacy />} />}
        ></Route>
        <Route
          path={"/terms-use"}
          element={
            <GeneralLayout
              className={"mb-5"}
              children={<TermsAndConditions />}
            />
          }
        ></Route>
        <Route
          path={"/cookie-policy"}
          element={
            <GeneralLayout className={"mb-5"} children={<CookiePolicy />} />
          }
        ></Route>

        <Route
          path={"/contact-us"}
          element={
            <GeneralLayout className={"mb-5"} children={<ContactUs />} />
          }
        ></Route>
        <Route
          path={"/about-us"}
          element={
            <GeneralLayout className={"mb-5"} children={<AboutUs />} />
          }
        ></Route>
        {/* <Route path={"/contact-us"} element={<Testing />}></Route> */}
      </Routes>
    </>
  );
};

export { Navigation };
